import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PENALTIES_ENUM } from 'services/queries';
import { INSERT_PENALTY } from 'services/mutations';
import { Loading } from 'Components';
import {
  Button,
  CenteredWrap,
  PenaltyInfoWrap,
  PenaltyPlayersWrap,
  Input,
  Label,
  Option,
  Req,
  Select,
} from 'styles';
import { makeTitle, sortPlayersByNumber } from 'utils';

const AdminPenalties = ({ attendance, gameData, gameId, refetchGameData }) => {
  const [penaltyData, setPenaltyData] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [timeError, setTimeError] = useState(false);
  const [timeValue, setTimeValue] = useState('');

  const requiredItems = [
    'gameId',
    'minutes',
    'penalty',
    'period',
    'playerId',
    'teamId',
    'time',
  ];

  useEffect(() => {
    if (!penaltyData.gameId || !penaltyData.minutes || !penaltyData.period) {
      const currentPenaltyData = {
        ...penaltyData,
        gameId: Number(gameId),
        minutes: 2,
        period: 1,
      };
      setPenaltyData(currentPenaltyData);
    }
  }, [gameId, penaltyData]);

  useEffect(() => {
    if (
      requiredItems.every((requiredItem) =>
        Object.keys(penaltyData).includes(requiredItem)
      ) &&
      !timeError
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [penaltyData, requiredItems, timeError]);

  const { data, error, loading } = useQuery(GET_PENALTIES_ENUM);

  const [insertPenalty, { loading: mutLoading }] = useMutation(INSERT_PENALTY);

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  const { penaltyEnum } = data;

  const teams = {
    awayTeam: gameData.awayTeam,
    homeTeam: gameData.homeTeam,
  };

  const handleUpdatePenalty = (e) => {
    const isInt = e.target.getAttribute('data-int');
    const key = e.target.id;
    const { value } = e.target;
    const currentPenaltyData = { ...penaltyData };

    if (value === '0' || value === 'null') {
      delete currentPenaltyData[key];
      setPenaltyData(currentPenaltyData);
    } else if (key === 'teamId') {
      setPenaltyData({
        teamId: Number(value),
        gameId,
        minutes: 2,
        period: 1,
      });
    } else {
      currentPenaltyData[key] = isInt ? Number(value) : value;
      setPenaltyData(currentPenaltyData);
    }
  };

  const handleTimeInput = (e) => {
    const time = e.target.value;
    const isValid = /^(0?\d|1[0-4]):[0-5]\d$/gm.test(time);
    setTimeValue(time);
    setTimeError(!isValid);
    if (isValid) {
      setPenaltyData({ ...penaltyData, time });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    insertPenalty({ variables: { penalty: penaltyData } }).then(() => {
      refetchGameData();
    });
  };

  const showPenaltyData = penaltyData?.teamId;
  const currentAttendance =
    attendance?.attendance?.length && showPenaltyData
      ? attendance.attendance.filter(
          (player) => player.teamId === penaltyData.teamId
        )
      : [];

  let currentRoster = attendance?.players.reduce(
    (teamRoster, currentPlayer) => {
      if (currentAttendance.find((att) => att.playerId === currentPlayer.id)) {
        return [...teamRoster, currentPlayer];
      }
      return teamRoster;
    },
    []
  );
  if (currentRoster?.length) {
    currentRoster = currentRoster.sort(sortPlayersByNumber);
  }

  return (
    <form onSubmit={handleSubmit}>
      <CenteredWrap>
        <Label htmlFor="teamId">
          <Req>*</Req>Team:
          <Select
            data-int
            defaultValue={0}
            id="teamId"
            onChange={handleUpdatePenalty}
            required
          >
            <Option value={0} disabled>
              Select a Team
            </Option>
            {Object.values(teams).map((team) => (
              <Option value={Number(team.id)} key={team.id}>
                {team.name}
              </Option>
            ))}
          </Select>
        </Label>
      </CenteredWrap>
      {showPenaltyData && (
        <>
          <PenaltyPlayersWrap>
            <Label htmlFor="playerId">
              <Req>*</Req>Player:
              <Select
                data-int
                defaultValue={0}
                name="playerId"
                id="playerId"
                onChange={handleUpdatePenalty}
                required
              >
                <Option value={0}>Select Player</Option>
                {currentRoster.map((player) => (
                  <Option value={player.id} key={player.id}>
                    {player.jersey}
                    {player.jerseySecondary
                      ? ` / ${player.jerseySecondary}`
                      : ''}{' '}
                    - {player.firstName} {player.lastName}
                  </Option>
                ))}
              </Select>
            </Label>
            <Label htmlFor="penalty">
              <Req>*</Req>Penalty:
              <Select
                defaultValue={0}
                name="penalty"
                id="penalty"
                onChange={handleUpdatePenalty}
                required
              >
                <Option value={0} disabled>
                  Select Penalty Type
                </Option>
                {[...penaltyEnum]?.sort((penA, penB) => {
                    if (penA.penalty < penB.penalty) {
                      return -1;
                    }
                    if (penA.penalty > penB.penalty) {
                      return 1;
                    }
                    return 0;
                }).map(({ penalty }) => (
                  <Option value={penalty} key={penalty}>
                    {makeTitle({ title: penalty, separator: '_' })}
                  </Option>
                ))}
              </Select>
            </Label>
          </PenaltyPlayersWrap>
          <PenaltyInfoWrap>
            <Label htmlFor="period">
              <Req>*</Req>Period:
              <Select
                data-int
                defaultValue={1}
                id="period"
                onChange={handleUpdatePenalty}
                required
              >
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>OT</Option>
              </Select>
            </Label>
            <Label htmlFor="time" error={timeError}>
              <Req>*</Req>Time:
              <Input
                id="time"
                onChange={handleTimeInput}
                type="text"
                value={timeValue}
              />
            </Label>
            <Label htmlFor="minutes">
              <Req>*</Req>Minutes:
              <Select
                data-int
                defaultValue={1}
                id="minutes"
                onChange={handleUpdatePenalty}
                required
              >
                <Option value={2}>2</Option>
                <Option value={5}>5</Option>
                <Option value={10}>10</Option>
              </Select>
            </Label>
          </PenaltyInfoWrap>
          <CenteredWrap>
            <Button type="submit" disabled={submitDisabled || mutLoading}>
              {mutLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </CenteredWrap>
        </>
      )}
    </form>
  );
};

export default AdminPenalties;
