import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PageContainer, SingleTeamSchedule, Table, TeamName } from 'Components';
import { ScrollWrapper } from 'styles';
import { CURRENT_SEASON, design } from 'utils';

const GoalieDataWrap = styled.div`
  margin-top: ${design.spacing[4]};
`;

const TeamPage = ({
  goalieData,
  goalieHeadCells,
  goalieRowLinks,
  schedule,
  seasonId,
  skaterData,
  skaterHeadCells,
  skaterRowLinks,
  team,
}) => {
  const {
    captainPlayer,
    champion,
    name,
    color,
    colorSecondary,
    division,
    seasonBySeason: { id: sId, season, year, league },
  } = team;
  const seasonString = `${season} ${year} (${league})${
    division ? ` - ${division}` : ''
  }`;
  console.log(captainPlayer)
  return (
    <PageContainer
      pageHeading={
        <>
          <h1>
            {champion ? '🏆 ' : ''}
            <TeamName
              name={name}
              color={color}
              colorSecondary={colorSecondary}
              wrap="true"
            />
            {champion ? ' 🏆' : ''}
          </h1>
          <h3>
            {sId !== CURRENT_SEASON ? (
              <Link to={`/archives/season/${sId}`}>{seasonString}</Link>
            ) : (
              seasonString
            )}
          </h3>
          {captainPlayer && (
            <h3>
              Captain:{' '}
              <Link to={`/player/${captainPlayer.id}`}>
                {captainPlayer.firstName} {captainPlayer.lastName}
              </Link>
            </h3>
          )}
        </>
      }
    >
      <Helmet>
        <title>{`${name} - ${season} ${year} | GBH Players Club`}</title>
      </Helmet>
      {skaterData.length > 0 && (
        <ScrollWrapper>
          <Table
            defaultOrder="desc"
            defaultOrderBy="points"
            enablePagination={false}
            enableToolbar={false}
            headCells={skaterHeadCells}
            rowLinks={skaterRowLinks}
            rows={skaterData}
            size="small"
            tableTitle="Player Statistics"
          />
        </ScrollWrapper>
      )}
      {goalieData.length > 0 && (
        <GoalieDataWrap>
          <ScrollWrapper>
            <Table
              defaultOrder="desc"
              defaultOrderBy="svp"
              enablePagination={false}
              enableToolbar={false}
              headCells={goalieHeadCells}
              rowLinks={goalieRowLinks}
              rows={goalieData}
              size="small"
              tableTitle="Goalie Statistics"
            />
          </ScrollWrapper>
        </GoalieDataWrap>
      )}
      {schedule.length > 0 && seasonId && (
        <ScrollWrapper>
          <SingleTeamSchedule games={schedule} seasonId={seasonId} />
        </ScrollWrapper>
      )}
    </PageContainer>
  );
};

TeamPage.propTypes = {
  goalieData: PropTypes.array,
  goalieHeadCells: PropTypes.array,
  goalieRowLinks: PropTypes.object,
  schedule: PropTypes.array,
  seasonId: PropTypes.number,
  skaterData: PropTypes.array,
  skaterHeadCells: PropTypes.array,
  skaterRowLinks: PropTypes.object,
  team: PropTypes.object,
};
TeamPage.defaultProps = {
  goalieData: [],
  goalieHeadCells: [],
  goalieRowLinks: [],
  schedule: [],
  seasonId: null,
  skaterData: [],
  skaterHeadCells: [],
  skaterRowLinks: [],
  team: {},
};

export default TeamPage;
