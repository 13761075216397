import styled from 'styled-components';
import { design } from 'utils';

export const Dropdown = styled.li`
  color: ${design.colors.white};
  cursor: pointer;
  display: block;
  position: relative;
  &:hover {
    color: ${design.colors.black};
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 5px;
      background-color: ${design.colors.white};
    }
    & ul {
      display: block;
    }
  }
  h3 {
    padding: ${design.spacing[2]} ${design.spacing[1]};
    @media screen and (min-width: ${design.breakpoints[1]}) {
      padding: ${design.spacing[2]};
    }
  }
  h3,
  h4 {
    margin: 0;
    @media screen and (min-width: ${design.breakpoints[2]}) {
      font-size: ${design.fontSizes[2]};
    }
  }
  a,
  h4 {
    display: block;
    font-size: ${design.fontSizes[2]};
    padding: ${design.spacing[2]} ${design.spacing[2]} ${design.spacing[2]} ${design.spacing[1.5]};
    &:hover {
      border-radius: ${design.radius[1]};
      color: ${design.colors.white} !important;
      background-color: ${design.colors.secondary};
    }
  }
  svg {
    margin-right: ${design.spacing[1]};
    width: 1.25rem !important;
    fill: currentColor;
    font-size: inherit;
    height: 1em;
    vertical-align: -0.125em;
  }
  ul {
    display: none;
    overflow: hidden;
    position: absolute;
    padding: ${design.spacing[1]};
    top: 100%;
    background-color: ${design.colors.white};
    box-shadow: ${design.shadow[0]};
    ${(props) =>
      props.right
        ? `
    right: 0;
    border-radius: ${design.radius[1]} 0 ${design.radius[1]} ${design.radius[1]};
    `
        : `
    left: 0;
    border-radius: 0 ${design.radius[1]} ${design.radius[1]};
    `}
    li {
      display: block;
      text-align: left;
      font-weight: normal;
      &:hover {
        background: 0;
      }
    }
  }
`;
