import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  GoalieSkaterToggle,
  PageContainer,
  PlayerPageContent,
  SeasonSelect,
} from 'Components';
import {
  createGoalieStatsCareerSeasons,
  createGoalieStatsCareerTotals,
  createGoalieStatsSeason,
  createSkaterStatsCareerSeasons,
  createSkaterStatsCareerTotals,
  createSkaterStatsSeason,
  design,
  CURRENT_SEASON,
} from 'utils';
import { goalieSeasonsHeadCells, skaterSeasonsHeadCells } from 'tableHeadCells';
import { HeadWrapGrid } from 'styles';

const TrophyWrap = styled.div`
  font-size: ${design.fontSizes[4]};
  text-align: center;
`;

const PlayerPage = ({ playerData, seasonId, setSeasonId }) => {
  const [statType, setStatType] = useState('skater');

  const {
    attendance,
    firstName,
    id: playerId,
    lastName,
    position,
    positionSecondary,
    skaterStats,
    careerStatsGoalie,
    rosters,
  } = playerData;

  let sortedAttendance = [];
  if (attendance?.length > 0) {
    sortedAttendance = [...attendance].sort((gameA, gameB) => {
      const gameIdA = gameA.schedule.id;
      const gameIdB = gameB.schedule.id;
      if (gameIdA > gameIdB) {
        return 1;
      }
      return -1;
    });
  }
  const goalieGames = sortedAttendance.filter((game) =>
    [game.schedule.awayGoalie, game.schedule.homeGoalie].includes(playerId)
  );
  const skaterGames = sortedAttendance.filter(
    (game) =>
      ![game.schedule.awayGoalie, game.schedule.homeGoalie].includes(playerId)
  );

  // Historical season by season skater stats
  const skaterStatsCareerSeasons = createSkaterStatsCareerSeasons(skaterStats, playerId);
  // Historical skater totals
  const skaterStatsCareerTotals = createSkaterStatsCareerTotals(skaterStats);
  // Historical season by season goalie stats
  const goalieStatsCareerSeasons =
    createGoalieStatsCareerSeasons(careerStatsGoalie, playerId);
  // Historical goalie totals
  const goalieStatsCareerTotals =
    createGoalieStatsCareerTotals(careerStatsGoalie);

  const currentTeamId = rosters[0]?.team?.id || null;
  const currentTeamName = rosters[0]?.team?.name || null;

  // Current season skater totals
  const skaterStatsSeason = createSkaterStatsSeason(skaterGames);
  // Current season goalie totals
  const goalieStatsSeason = createGoalieStatsSeason(goalieGames, playerId);

  const hasSkaterStats =
    skaterGames.length > 0 || skaterStatsCareerSeasons.length > 0;
  const hasGoalieStats =
    goalieGames.length > 0 || goalieStatsCareerSeasons.length > 0;
  const showToggle = hasSkaterStats && hasGoalieStats;

  useEffect(() => {
    if (
      !hasSkaterStats ||
      (skaterGames.length === 0 && goalieGames.length > 0)
    ) {
      setStatType('goalie');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSkater = statType === 'skater';

  const handleSeasonChange = (e) => {
    setSeasonId(e.target.value);
  };

  return (
    <PageContainer
      pageHeading={
        <>
          <HeadWrapGrid>
            {showToggle ? (
              <GoalieSkaterToggle
                statType={statType}
                setStatType={setStatType}
              />
            ) : (
              <div />
            )}
            <h1>
              {firstName} {lastName}
            </h1>
            <div>
              <SeasonSelect
                value={seasonId}
                onChange={handleSeasonChange}
                league="GBHPC"
              />
            </div>
          </HeadWrapGrid>
          <h3>
            {position}
            {positionSecondary && ` / ${positionSecondary}`}
          </h3>
          {(skaterStatsCareerTotals.champion.length > 0 ||
            goalieStatsCareerTotals.champion.length > 0) && (
            <TrophyWrap>
              {skaterStatsCareerTotals.champion.map(() => ` ${design.trophy}`)}
              {goalieStatsCareerTotals.champion.map(() => ` ${design.trophy}`)}
            </TrophyWrap>
          )}
        </>
      }
    >
      <Helmet>
        <title>
          {firstName} {lastName} | GBH Players Club
        </title>
      </Helmet>
      <h2>
        <Link to={`/team/${CURRENT_SEASON}/${currentTeamId}`}>
          {currentTeamName}
        </Link>
      </h2>
      <PlayerPageContent
        careerHeadCells={
          isSkater ? skaterSeasonsHeadCells : goalieSeasonsHeadCells
        }
        careerStatsBySeason={
          isSkater ? skaterStatsCareerSeasons : goalieStatsCareerSeasons
        }
        careerTotals={
          isSkater ? skaterStatsCareerTotals : goalieStatsCareerTotals
        }
        gamesPlayed={isSkater ? skaterGames : goalieGames}
        playerId={playerId}
        seasonId={seasonId}
        seasonStats={isSkater ? skaterStatsSeason : goalieStatsSeason}
        team={rosters[0]?.team}
        type={statType}
      />
    </PageContainer>
  );
};

PlayerPage.propTypes = {
  playerData: PropTypes.shape({
    attendance: PropTypes.array,
    firstName: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    position: PropTypes.string,
    positionSecondary: PropTypes.string,
    skaterStats: PropTypes.array,
    careerStatsGoalie: PropTypes.array,
    rosters: PropTypes.array,
  }).isRequired,
  seasonId: PropTypes.number.isRequired,
  setSeasonId: PropTypes.func.isRequired,
};

export default PlayerPage;
