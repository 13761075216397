import styled from 'styled-components';
import { design } from 'utils';

export const CenterTd = styled.td`
  text-align: center;
`;
export const StyledTable = styled.table`
  margin: ${design.spacing[1]} auto;
  border-radius: ${design.radius[1]};
  border-collapse: initial;
  overflow: hidden;
  width: 100%;
  border: 2px solid ${design.colors.secondary};
  th {
    font-weight: bold;
    padding: ${design.spacing[1]};
    text-align: center;
  }
  thead th {
    background-color: ${design.colors.secondary};
    color: ${design.colors.white};
    line-height: 1.5;
  }
  tbody {
    th {
      background-color: ${design.colors.primary};
      color: ${design.colors.white};
    }
    tr {
      background-color: ${design.colors.white};
      &:nth-child(2n + 1) {
        background-color: ${design.colors.gray[1]};
      }
    }
    td {
      padding: ${design.spacing[1]} ${design.spacing[2]};
    }
  }
  ${(props) =>
    props.solidHeader &&
    `
    thead {
      th {
        background-color: ${design.colors.secondary};
        color: ${design.colors.white};
      }
    }
  `}
  ${(props) =>
    props.largeMargins &&
    `
    margin: ${design.spacing[4]} auto ${design.spacing[2]};
  `}
  ${(props) =>
    props.fixed &&
    `
    table-layout: fixed;
  `}
`;
