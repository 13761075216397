import styled from 'styled-components';
import { design } from 'utils';

export const Toggle = styled.div`
  display: grid;
  border: 1px solid ${design.colors.secondary};
  border-radius: ${design.radius[1]};
  overflow: hidden;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  button {
    color: ${design.colors.primary};
    font-size: ${design.fontSizes[2]};
    background: 0;
    padding: ${design.spacing[1]} ${design.spacing[2]};
    border: 0;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background-color: ${design.colors.primary};
      color: ${design.colors.white};
    }
    &:disabled {
      background-color: ${design.colors.secondary};
      color: ${design.colors.white};
      cursor: initial;
    }
  }
`;
