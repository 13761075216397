import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import Whistle from 'assets/Whistle';
import { Dropdown } from 'styles';

const LeagueDropdown = () => {
  return (
    <Dropdown>
      <h3>League</h3>
      <ul>
        <li>
          <Link to="/rules">
            <FontAwesomeIcon icon={faBalanceScale} />
            League Rules
          </Link>
        </li>
        <li>
          <Link to="/refs">
            <Whistle />
            Ref Guidelines
          </Link>
        </li>
      </ul>
    </Dropdown>
  );
};

export default LeagueDropdown;
