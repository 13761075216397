export const design = {
  colors: {
    primary: 'rgb(0, 107, 70)',
    secondary: 'rgb(0, 82, 52)',
    tertiary: 'rgb(235, 242, 239)',
    gold: 'rgb(212, 175, 55)',
    white: 'rgb(255, 255, 255)',
    black: 'rgb(1, 1, 1)',
    gray: {
      1: 'rgb(244, 244, 244)',
      2: 'rgb(211, 211, 211)',
      3: 'rgb(170, 170, 170)',
      5: 'rgb(120, 120, 120)',
      7: 'rgb(70, 70, 70)',
      8: 'rgb(45, 45, 45)',
      9: 'rgb(20, 20, 20)',
    },
    gbhpcHome: 'rgb(59, 146, 18)',
    gbhpcHomeLoss: 'rgba(59, 146, 18, 0.4)',
    gbhpcAway: 'rgb(1, 1, 1)',
    gbhpcAwayLoss: 'rgba(1, 1, 1, 0.3)',
    error: 'rgb(214, 15, 15)',
    emphasis: 'rgb(214, 15, 15)',
  },
  spacing: {
    0: 0,
    0.25: '.125rem',
    0.5: '.25rem',
    1: '.5rem',
    1.5: '.75rem',
    2: '1rem',
    3: '1.5rem',
    4: '2rem',
    5: '2.5rem',
    6: '3rem',
    7: '3.5rem',
    8: '4rem',
  },
  fontSizes: {
    1: '.875rem',
    2: '1rem',
    2.5: '1.25rem',
    3: '1.5rem',
    4: '2rem',
    5: '2.5rem',
    6: '3rem',
    7: '3.5rem',
    8: '4rem',
  },
  breakpoints: {
    1: '360px',
    2: '480px',
    3: '600px',
    4: '768px',
    5: '992px',
    7: '1024px',
    10: '1280px',
    12: '1440px',
    14: '1600px',
    16: '1920px',
  },
  radius: {
    0: '.25rem',
    1: '.5rem',
  },
  trophy: `🏆`,
  shadow: {
    0: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    1: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    2: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  },
};

// Blue color set:
// colors: {
// primary: '#015cab',
// secondary: '#06345c',
// tertiary: '#c2daef',
// white: '#fff',
// black: '#000',
// },
