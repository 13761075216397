import React from 'react';
import { Helmet } from 'react-helmet';
import { PageContainer } from 'Components';
import { design } from 'utils';

const RefsPage = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>Referee Guidelines | GBH Players Club</title>
      </Helmet>
      <h1>Referee Guidelines</h1>
      <div style={{marginTop: design.spacing[8]}}>
        <h3>Coming soon...</h3>
      </div>
    </PageContainer>
  );
};

export default RefsPage;
