import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { design, formatTime, CURRENT_SEASON } from 'utils';

const BracketGame = styled.section`
  text-align: center;
  width: 100%;
  justify-self: center;
  display: flex;
  flex-direction: column;
  max-width: ${design.breakpoints[4]};
  border-width: 3px;
  border-radius: ${design.radius[1]};
  @media screen and (min-width: ${design.breakpoints[5]}) {
    &:nth-child(2) {
      grid-area: 1 / 3 / 1 / 3;
    }
    &:nth-child(3) {
      grid-area: 1 / 2 / 1 / 2;
    }
  }
  h3 {
    flex-grow: 0;
    color: ${design.colors.white};
    font-weight: bold;
    margin: 0;
    padding: ${design.spacing[1]};
  }
  ${(props) =>
    props.isChampionship
      ? `
    background-color: ${design.colors.gold};
    border: solid ${design.colors.gold};
    order: 1;
    `
      : `
    background-color: ${design.colors.secondary};
    border: solid ${design.colors.secondary};
    order: 2;
  `}
`;
const GameContent = styled.div`
  padding: ${design.spacing[2]};
  background-color: ${design.colors.white};
  border-radius: ${design.radius[1]};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const TeamWrap = styled.div`
  position: relative;
`;
const MatchupHeadings = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${design.fontSizes[1]};
  color: ${design.colors.white};
  padding: ${design.spacing[1]} ${design.spacing[2]};
  border-radius: ${design.radius[1]} ${design.radius[1]} 0 0;
  ${props => props.isChampionship ? `
    background-color: ${design.colors.gold};
    ` : `
    background-color: ${design.colors.secondary};
  `}
`;
const TeamName = styled.p`
  padding: ${design.spacing[2]} ${design.spacing[3]};
  display: flex;
  justify-content: space-between;
  position: relative;
  &,
  a {
    color: ${design.colors.white};
  }
  &:first-child {
    z-index: 1;
  }
  &:last-child {
    border-radius: 0 0 ${design.radius[1]} ${design.radius[1]};
  }
  ${(props) =>
    props.isHome
      ? `
  background-color: ${design.colors.gbhpcHome};
  `
      : `
  background-color: ${design.colors.gbhpcAway};
  `}
  ${(props) =>
    props.isWinner
      ? `
    font-weight: bold;
  `
      : ''}
  ${(props) =>
    props.isLoser && props.isHome
      ? `
    background-color: ${design.colors.gbhpcHomeLoss};
    &, a {
      color: ${design.colors.gray[5]};
    }
    a {
      text-decoration: line-through;
    }`
      : ''}
  ${(props) =>
    props.isLoser && !props.isHome
      ? `
    background-color: ${design.colors.gbhpcAwayLoss};
    &, a {
      color: ${design.colors.gray[5]};
    }
    a {
      text-decoration: line-through;
    }`
      : ``}
`;
const GameInfo = styled.div`
  margin: ${design.spacing[1]} 0 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  p {
    margin-top: ${design.spacing[1]};
    &:first-child {
      a {
        font-weight: bold;
      }
    }
  }
`;

const PlayoffBracketGame = ({
  awayScore,
  awaySeed,
  awayTeam,
  date,
  homeScore,
  homeSeed,
  homeTeam,
  id,
  isChampionship,
  roundLabel,
  time,
  rinkLocation,
}) => {
  const day = new Date(date);
  const nextDay = day.setDate(day.getDate() + 1);
  const isoString = new Date(nextDay).toISOString();
  const dateArr = new Date(isoString).toDateString().split(' ');
  const homeWinner = homeScore > awayScore;
  const awayWinner = awayScore > homeScore;

  const readableDate = `
    ${dateArr[0]}day ${dateArr[1]}. ${dateArr[2]}, ${dateArr[3]}
  `;
  return (
    <BracketGame isChampionship={isChampionship}>
      <h3>{roundLabel}</h3>
      <GameContent>
        <MatchupHeadings isChampionship={isChampionship}>
          <span>Seed</span>
          <span>Team</span>
          <span>Score</span>
        </MatchupHeadings>
        <TeamWrap>
          <TeamName isHome isWinner={homeWinner} isLoser={awayWinner}>
            <span>{homeSeed}</span>
            {homeTeam?.name ? (
              <Link to={`/team/${CURRENT_SEASON}/${homeTeam.id}`}>
                {homeTeam.name}
              </Link>
            ) : (
              'TBD'
            )}
            <span>{homeScore}</span>
          </TeamName>
          <TeamName isWinner={awayWinner} isLoser={homeWinner}>
            <span>{awaySeed}</span>
            {awayTeam?.name ? (
              <Link to={`/team/${CURRENT_SEASON}/${awayTeam.id}`}>
                {awayTeam.name}
              </Link>
            ) : (
              'TBD'
            )}
            <span>{awayScore}</span>
          </TeamName>
        </TeamWrap>
        <GameInfo>
          {(homeWinner || awayWinner) && (
            <p>
              <Link to={`/game/${id}`}>Game Summary</Link>
            </p>
          )}
          <p>{formatTime(time)}</p>
          <p>{readableDate}</p>
          <p>
            <Link to={`/rink/${rinkLocation.id}`}>
              {rinkLocation.name}, {rinkLocation.city}
            </Link>
          </p>
        </GameInfo>
      </GameContent>
    </BracketGame>
  );
};

PlayoffBracketGame.propTypes = {
  awayScore: PropTypes.number,
  awaySeed: PropTypes.number,
  awayTeam: PropTypes.object,
  date: PropTypes.string.isRequired,
  homeScore: PropTypes.number,
  homeSeed: PropTypes.number,
  homeTeam: PropTypes.object,
  id: PropTypes.number.isRequired,
  isChampionship: PropTypes.bool,
  roundLabel: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  rinkLocation: PropTypes.object.isRequired,
};

PlayoffBracketGame.defaultProps = {
  awayScore: null,
  awaySeed: null,
  awayTeam: {},
  homeScore: null,
  homeSeed: null,
  homeTeam: {},
  isChampionship: false,
};

export default PlayoffBracketGame;
