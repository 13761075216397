import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'styles';

const MerchDropdown = () => {
  return (
    <Dropdown right>
      <h3>Merch</h3>
      <ul>
        <li>
          <a
            href="https://forms.gle/oBgufPqh8oypwTaj7"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faClipboard} />
            Jersey Order Form
          </a>
        </li>
        <li>
          <a
            href="https://teamlocker.squadlocker.com/#/lockers/gbhpc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faShoppingCart} />
            Squadlocker Store
          </a>
        </li>
      </ul>
    </Dropdown>
  );
};

export default MerchDropdown;
