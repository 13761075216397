import React from 'react'
import styled from 'styled-components'
import { design } from 'utils';

const FooterWrap = styled.footer`
  background-color: ${design.colors.black};
  color: ${design.colors.white};
  font-size: ${design.fontSizes[1]};
  div {
    margin: auto;
    display flex;
    padding: ${design.spacing[2]} ${design.spacing[4]};
    max-width: ${design.breakpoints[14]};  
    p:last-child {
      margin-left: auto;
    }
  }
`;

const SiteFooter = () => {
  return (
    <FooterWrap>
      <div>
        <p>
        &copy; GBH Players Club 2024
        </p>
      </div>
    </FooterWrap>)
}

export default SiteFooter