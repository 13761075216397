import styled from 'styled-components';
import { design } from 'utils';

export const TeamStatTable = styled.table`
  width: 100%;
  border-radius: ${design.radius[1]};
  thead tr {
    border: 2px solid
      ${(props) =>
        props.isHome ? design.colors.gbhpcHome : design.colors.gbhpcAway};
    color: white;
    text-align: center;
    th {
      background-color: ${(props) =>
        props.isHome ? design.colors.gbhpcHome : design.colors.gbhpcAway};
    }
  }
  th,
  td {
    padding: ${design.spacing[1]} ${design.spacing[2]};
  }
  tbody {
    border: solid ${design.colors.secondary};
    border-width: 0 2px 2px 2px;
    td {
      vertical-align: middle;
    }
    tr:nth-child(2n + 1) {
      background-color: ${design.colors.gray[1]};
    }
    td:nth-child(n + 3) {
      text-align: center;
    }
  }
`;
