import { gql } from '@apollo/client';

const PLAYER_STATS_FRAGMENT = gql`
  fragment PlayerStats on careerStats {
    id
    gamesPlayed
    goals
    assists
    pim
  }
`;
const GOALIE_STATS_FRAGMENT = gql`
  fragment GoalieStats on careerStatsGoalie {
    assists
    gamesPlayed
    goalsAgainst
    id
    loss
    otl
    pim
    shotsAgainst
    shutouts
    sol
    team
    tie
    win
  }
`;
const PLAYER_SEASON_STAT_FRAGMENT = gql`
  fragment PlayerSeasonStats on players {
    attendance_aggregate(
      where: { schedule: { seasonId: { _eq: $seasonId } } }
    ) {
      aggregate {
        count
      }
    }
    goals_aggregate(where: { schedule: { seasonId: { _eq: $seasonId } } }) {
      aggregate {
        count
      }
    }
    primaryAssist_aggregate(
      where: { schedule: { seasonId: { _eq: $seasonId } } }
    ) {
      aggregate {
        count
      }
    }
    secondaryAssist_aggregate(
      where: { schedule: { seasonId: { _eq: $seasonId } } }
    ) {
      aggregate {
        count
      }
    }
    penalties_aggregate(where: { schedule: { seasonId: { _eq: $seasonId } } }) {
      aggregate {
        sum {
          minutes
        }
      }
    }
  }
`;

const PLAYER_INFO_FRAGMENT = gql`
  fragment PlayerInfo on players {
    id
    firstName
    lastName
    jersey
    jerseySecondary
    position
    positionSecondary
  }
`;

const SCHEDULE_FRAGMENT = gql`
  fragment ScheduleFragment on schedule {
    id
    date
    attendance {
      player {
        firstName
        lastName
      }
      playerId
      teamId
    }
    away
    awayGoalie
    awayScore
    awayShots
    awayTeam {
      name
      abbreviation
      id
      color
      colorSecondary
    }
    cancel
    eng: goals(where: { en: { _eq: true } }) {
      teamId
    }
    home
    homeGoalie
    homeScore
    homeShots
    homeTeam {
      name
      abbreviation
      id
      color
      colorSecondary
    }
    goals {
      goal
      en
      primaryAssist
      secondaryAssist
      teamId
    }
    note
    penalties {
      minutes
      playerId
    }
    pickup
    ot
    rinkLocation {
      address
      city
      name
      id
      website
    }
    playoffs
    season {
      season
      year
    }
    seasonId
    time
  }
`;

export const GET_LEAGUE_SEASONS = gql`
  query getSeasons($league: leagues_enum) {
    seasons(where: { league: { _eq: $league } }, order_by: { id: desc }) {
      league
      id
      season
      year
    }
  }
`;

export const GET_STATS = gql`
  ${PLAYER_STATS_FRAGMENT}
  ${PLAYER_INFO_FRAGMENT}
  query GetStats($seasonId: Int) {
    careerStats(where: { seasonBySeason: { id: { _eq: $seasonId } } }) {
      ...PlayerStats
      playerByPlayer {
        ...PlayerInfo
      }
      seasonBySeason {
        year
        season
      }
      teamByTeam {
        abbreviation
        color
        colorSecondary
        champion
        id
        name
      }
    }
  }
`;

export const GET_STATS_GOALIE = gql`
  ${GOALIE_STATS_FRAGMENT}
  ${PLAYER_INFO_FRAGMENT}
  query GetStats($seasonId: Int) {
    careerStatsGoalie(where: { seasonBySeason: { id: { _eq: $seasonId } } }) {
      ...GoalieStats
      playerByPlayer {
        ...PlayerInfo
      }
      seasonBySeason {
        year
        season
      }
      teamByTeam {
        abbreviation
        color
        colorSecondary
        champion
        id
        name
      }
    }
  }
`;

export const GET_PLAYER_INFO = gql`
  ${PLAYER_STATS_FRAGMENT}
  query GetPlayerInfo($playerId: Int!, $currentSeasonId: Int!) {
    players(where: { id: { _eq: $playerId } }) {
      firstName
      lastName
      id
      position
      positionSecondary
      gbhpc
      rosters(where: { team: { season: { _eq: $currentSeasonId } } }) {
        team {
          id
          name
        }
      }
      attendance(where: { schedule: { seasonId: { _eq: $currentSeasonId } } }) {
        teamId
        schedule {
          awayGoalie
          awayScore
          awayShots
          awayTeam {
            id
            name
          }
          cancel
          date
          eng: goals(where: { en: { _eq: true } }) {
            teamId
          }
          goals {
            en
          }
          homeGoalie
          homeScore
          homeShots
          homeTeam {
            id
            name
          }
          id
          ot
          playoffs
          time
          goals_aggregate(where: { goal: { _eq: $playerId } }) {
            aggregate {
              count
            }
          }
          primaryAssists: goals_aggregate(
            where: { primaryAssist: { _eq: $playerId } }
          ) {
            aggregate {
              count
            }
          }
          secondaryAssists: goals_aggregate(
            where: { secondaryAssist: { _eq: $playerId } }
          ) {
            aggregate {
              count
            }
          }
          penalties_aggregate(where: { playerId: { _eq: $playerId } }) {
            aggregate {
              sum {
                minutes
              }
            }
          }
        }
      }
      skaterStats {
        ...PlayerStats
        seasonBySeason {
          id
          season
          year
          league
        }
        teamByTeam {
          id
          name
          captainPlayer {
            id
          }
          color
          colorSecondary
          champion
        }
      }
      careerStatsGoalie {
        id
        gamesPlayed
        win
        loss
        tie
        otl
        sol
        shotsAgainst
        goalsAgainst
        shutouts
        assists
        pim
        seasonBySeason {
          id
          season
          year
          league
        }
        teamByTeam {
          id
          name
          captainPlayer {
            id
          }
          color
          colorSecondary
          champion
        }
      }
    }
  }
`;

export const GET_TEAM_NAMES = gql`
  query GetTeamNames($seasonId: Int!) {
    teams(where: { season: { _eq: $seasonId } }) {
      id
      name
    }
  }
`;

export const GET_TEAMS = gql`
  query GetTeams {
    teams(order_by: { id: asc }) {
      id
      champion
      color
      colorSecondary
      name
      seasonBySeason {
        id
        league
        season
        year
      }
      captainPlayer {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_TEAM_INFO = gql`
  ${PLAYER_INFO_FRAGMENT}
  ${PLAYER_STATS_FRAGMENT}
  ${SCHEDULE_FRAGMENT}
  query GetPlayersByTeam($teamId: Int!, $seasonId: Int!) {
    teams(where: { id: { _eq: $teamId } }) {
      champion
      color
      colorSecondary
      division
      name
      captainPlayer {
        firstName
        lastName
        id
      }
      rosters {
        players {
          ...PlayerInfo
        }
      }
      seasonBySeason {
        id
        season
        year
        league
        schedules(where: { seasonId: { _eq: $seasonId } }) {
          ...ScheduleFragment
        }
      }
    }
    players(
      where: {
        _or: [
          { positionSecondary: { _eq: GOALIE } }
          { position: { _eq: GOALIE } }
        ]
        rosters: { teamId: { _eq: $teamId } }
      }
    ) {
      id
      position
      firstName
      lastName
      jersey
      jerseySecondary
    }
  }
`;

export const GET_SCHEDULE_BY_SEASON_ID = gql`
  ${SCHEDULE_FRAGMENT}
  query GetScheduleBySeasonId($seasonId: Int!, $teamId: Int, $date: date) {
    schedule(
      order_by: { date: asc, time: asc }
      where: {
        seasonId: { _eq: $seasonId }
        date: { _gte: $date }
        home: { _eq: $teamId }
        away: { _eq: $teamId }
      }
    ) {
      ...ScheduleFragment
    }
  }
`;

export const GET_SINGLE_GAME_INFO = gql`
  query ($id: Int!, $teams: [Int!]) {
    schedule(where: { id: { _eq: $id } }) {
      id
      awayGoalie
      awayScore
      awayShots
      awayTeam {
        abbreviation
        name
        id
      }
      cancel
      division
      date
      homeGoalie
      homeScore
      homeShots
      homeTeam {
        abbreviation
        id
        name
      }
      location
      ot
      playoffs
      seasonId
      time
      rinkLocation {
        city
        name
        id
      }
      attendance {
        teamId
        player {
          id
          firstName
          lastName
          position
          positionSecondary
          jersey
          jerseySecondary
          rosters {
            team {
              id
              name
            }
          }
        }
      }
      goals(order_by: { time: asc, id: asc }) {
        id
        period
        gw
        pp
        sh
        en
        teamId
        time
        goalPlayer {
          firstName
          lastName
          id
        }
        primaryAssistPlayer {
          firstName
          lastName
          id
        }
        secondaryAssistPlayer {
          firstName
          lastName
          id
        }
      }
      penalties {
        id
        minutes
        penalty
        period
        player {
          id
          firstName
          lastName
        }
        teamId
        time
      }
    }
  }
`;

export const GET_HOMEPAGE_DATA = gql`
  ${PLAYER_INFO_FRAGMENT}
  ${PLAYER_SEASON_STAT_FRAGMENT}
  ${SCHEDULE_FRAGMENT}
  query ($date: date, $seasonId: Int!, $seasonsArr: [Int!], $gameLimit: Int) {
    seasons(where: { id: { _in: $seasonsArr } }) {
      id
      league
      season
      year
      teams(where: { champion: { _eq: true } }) {
        name
        id
      }
    }
    schedule(
      where: { date: { _gte: $date }, seasonId: { _eq: $seasonId } }
      limit: $gameLimit
      order_by: { date: asc, time: asc }
    ) {
      ...ScheduleFragment
      goalieAttendance: attendance(
        where: {
          _or: [
            { player: { positionSecondary: { _eq: GOALIE } } }
            { player: { position: { _eq: GOALIE } } }
          ]
        }
      ) {
        teamId
        player {
          id
          firstName
          lastName
          jersey
          jerseySecondary
          rosters {
            team {
              id
              name
            }
          }
        }
      }
    }
    teams(where: { seasonBySeason: { id: { _eq: $seasonId } } }) {
      id
      abbreviation
      color
      colorSecondary
      name
      rosters {
        players {
          ...PlayerInfo
          ...PlayerSeasonStats
        }
      }
    }
  }
`;

export const GET_CURRENT_SEASON_STAT_DATA = gql`
  ${PLAYER_INFO_FRAGMENT}
  ${PLAYER_SEASON_STAT_FRAGMENT}
  query ($seasonId: Int) {
    teams(where: { seasonBySeason: { id: { _eq: $seasonId } } }) {
      id
      abbreviation
      name
      color
      colorSecondary
      rosters(where: { team: { seasonBySeason: { id: { _eq: $seasonId } } } }) {
        players {
          ...PlayerInfo
          ...PlayerSeasonStats
        }
      }
    }
  }
`;

export const GET_RINK_INFO = gql`
  query ($rinkId: Int) {
    rinks(where: { id: { _eq: $rinkId } }) {
      address
      city
      mapString
      name
      id
      website
    }
  }
`;

export const GET_ARCHIVE_TEAM_INFO = gql`
  ${GOALIE_STATS_FRAGMENT}
  ${PLAYER_STATS_FRAGMENT}
  ${PLAYER_INFO_FRAGMENT}
  query GetPlayersByTeam($teamId: Int!) {
    teams(where: { id: { _eq: $teamId } }) {
      champion
      color
      colorSecondary
      division
      name
      captainPlayer {
        id
        firstName
        lastName
      }
      skaterStats {
        ...PlayerStats
        playerByPlayer {
          ...PlayerInfo
        }
      }
      careerStatsGoalie {
        ...GoalieStats
        playerByPlayer {
          ...PlayerInfo
        }
      }
      seasonBySeason {
        id
        season
        year
        league
      }
    }
  }
`;

export const GET_ARCHIVE_SEASONS = gql`
  query GetArchiveSeasons {
    seasons {
      id
      league
      season
      year
      teams(where: { champion: { _eq: true } }) {
        color
        colorSecondary
        division
        id
        name
        captainPlayer {
          firstName
          id
          lastName
        }
      }
    }
  }
`;

export const GET_ARCHIVE_SINGLE_SEASON_INFO = gql`
  ${PLAYER_STATS_FRAGMENT}
  ${PLAYER_INFO_FRAGMENT}
  ${SCHEDULE_FRAGMENT}
  query GetArchiveSingleSeasonInfo($seasonId: Int!) {
    schedule(
      where: { seasonId: { _eq: $seasonId } }
      order_by: { date: asc, time: asc }
    ) {
      ...ScheduleFragment
      goalieAttendance: attendance(
        where: {
          _or: [
            { player: { positionSecondary: { _eq: GOALIE } } }
            { player: { position: { _eq: GOALIE } } }
          ]
        }
      ) {
        teamId
        player {
          id
          firstName
          lastName
          jersey
          jerseySecondary
          rosters {
            team {
              id
              name
            }
          }
        }
      }
    }
    seasons(where: { id: { _eq: $seasonId } }) {
      id
      league
      season
      year
      teams {
        champion
        color
        colorSecondary
        division
        id
        name
        captainPlayer {
          firstName
          id
          lastName
        }
        skaterStats {
          ...PlayerStats
          playerByPlayer {
            ...PlayerInfo
          }
        }
      }
    }
  }
`;

export const GET_PLAYER_EXPORT_INFO = gql`
  ${PLAYER_STATS_FRAGMENT}
  ${PLAYER_INFO_FRAGMENT}
  query GetStats($leagues: [leagues_enum!]) {
    careerStats(where: { seasonBySeason: {league: {_in: $leagues}}}) {
      ...PlayerStats
      draftRound
      playerByPlayer {
        ...PlayerInfo
        contact
        email
        gbhpc
        lastSeason
      }
      seasonBySeason {
        id
        year
        season
      }
      teamByTeam {
        id
        name
        color
        colorSecondary
        champion
      }
    }
  }
`;

export const GET_ADMIN_ATTENDANCE_DATA = gql`
  query GetAdminAttendanceData(
    $gameId: Int
    $seasonId: Int
    $subSeasons: [Int!]
  ) {
    attendance(where: { gameId: { _eq: $gameId } }) {
      id
      playerId
      teamId
    }
    rosters(where: { team: { season: { _eq: $seasonId } } }) {
      teamId
      players {
        id
      }
    }
    players(where: {lastSeason: { _in: $subSeasons}}) {
      id
      firstName
      lastName
      jersey
      jerseySecondary
      position
      positionSecondary
      rosters(limit: 1, order_by: {teamId: desc}) {
        teamId
      }
    }
  }
`;

export const GET_ADMIN_ROSTER_DATA = gql`
  query GetAdminRostereData($seasonId: Int) {
    players(where: { lastSeason: { _eq: $seasonId } }) {
      id
      lastName
      firstName
      position
      positionSecondary
      rosters(where: { team: { season: { _eq: $seasonId } } }) {
        teamId
      }
    }
    teams(where: { season: { _eq: $seasonId } }) {
      name
      id
    }
  }
`;

export const GET_PENALTIES_ENUM = gql`
  query GetPenaltiesEnum {
    penaltyEnum {
      penalty
    }
  }
`;
