import React from 'react';
import styled from 'styled-components';
import { AdminNav, Nav } from 'Components';
import headerLogo from 'assets/gbhpc-web-header-bw-cheevers-transparent-4.png';
import { design } from 'utils';

const Header = styled.header`
  background-color: ${design.colors.black};
  position: sticky;
  top: 0;
  z-index: 1000;
`;
const HeaderInner = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  max-width: ${design.breakpoints[14]};
  padding: ${design.spacing[2]} ${design.spacing[4]} 0;
  width: 100%;
  @media screen and (max-width: ${design.breakpoints[10]}) {
    padding: ${design.spacing[2]} ${design.spacing[2]} 0;
  }
`;
const SiteTitle = styled.h2`
  margin: 0 auto ${design.spacing[2]} ${design.spacing[2]};
  text-align: center;
  @media screen and (min-width: ${design.breakpoints[5]}) {
    text-align: left;
    width: auto;
  }
  a {
    display: block;
  }
  img {
    max-width: 100%;
  }
`;

const SiteHeader = ({ isAdmin }) => {
  return (
    <Header>
      <HeaderInner>
        <SiteTitle>
          <a href="/">
            <img src={headerLogo} alt="GBH Players Club" />
          </a>
        </SiteTitle>
        <Nav />
      </HeaderInner>
      {isAdmin && <AdminNav />}
    </Header>
  );
};

export default SiteHeader;
