import React from 'react';
import styled from 'styled-components';
import { PlayoffBracketGame } from 'Components';
import { design } from 'utils';

const BracketWrap = styled.div`
  margin: 0 0 ${design.spacing[2]};
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${design.spacing[4]};
  row-gap: ${design.spacing[4]};
  @media screen and (min-width: ${design.breakpoints[5]}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const PlayoffBracket = ({ games }) => {
  console.log(games)
  return (
    <BracketWrap>
      <PlayoffBracketGame
        awayScore={games[0].awayScore}
        awaySeed={3}
        awayTeam={games[0].awayTeam}
        date={games[0].date}
        homeScore={games[0].homeScore}
        homeSeed={2}
        homeTeam={games[0].homeTeam}
        id={games[0].id}
        roundLabel="First Round"
        time={games[0].time}
        rinkLocation={games[0].rinkLocation}
      />
      <PlayoffBracketGame
        awayScore={games[1].awayScore}
        awaySeed={4}
        awayTeam={games[1].awayTeam}
        date={games[1].date}
        homeScore={games[1].homeScore}
        homeSeed={1}
        homeTeam={games[1].homeTeam}
        id={games[1].id}
        roundLabel="First Round"
        time={games[1].time}
        rinkLocation={games[1].rinkLocation}
      />
      <PlayoffBracketGame
        awayScore={games[2].awayScore}
        awaySeed={3}
        awayTeam={games[2].awayTeam}
        date={games[2].date}
        homeScore={games[2].homeScore}
        homeSeed={1}
        homeTeam={games[2].homeTeam}
        id={games[2].id}
        isChampionship
        roundLabel={`${design.trophy} Championship ${design.trophy}`}
        time={games[2].time}
        rinkLocation={games[2].rinkLocation}
      />
    </BracketWrap>
  );
};

export default PlayoffBracket;
