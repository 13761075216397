import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faListAlt } from '@fortawesome/free-regular-svg-icons';
import { Dropdown } from 'styles';

const ArchiveDropdown = () => {
  return (
    <Dropdown right>
      <h3>Archives</h3>
      <ul>
        <li>
          <Link to="/archives/stats">
            <FontAwesomeIcon icon={faChartBar} />
            Career Stats
          </Link>
        </li>
        <li>
          <Link to="/archives/seasons">
            <FontAwesomeIcon icon={faCalendarAlt} />
            Past Seasons
          </Link>
        </li>
        <li>
          <Link to="/archives/teams">
            <FontAwesomeIcon icon={faListAlt} />
            Historical Teams
          </Link>
        </li>
      </ul>
    </Dropdown>
  );
};

export default ArchiveDropdown;
