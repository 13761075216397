import { createGlobalStyle } from 'styled-components';
import { design } from 'utils';

const GlobalStyles = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1.43;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html, body, #___gatsby, #___gatsby > div, #root {
  min-height: 100vh;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 8rem;
}
body {
  background-color: ${design.colors.primary}
}
h1,
h2,
h3 {
  flex-grow: 1;
  width: 100%;
  text-align: center;
  margin-bottom: ${design.spacing[2]};
}
h1 {
  font-size: ${design.fontSizes[4]};
}
h2 {
  font-size: ${design.fontSizes[3]}
}
h3 {
  font-size: ${design.fontSizes[2]}
}
a {
  color: ${design.colors.primary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.sr-only {
  width: 1;
  height: 1;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}
html {
  .MuiButton-root {
    font-size: 1rem;
    text-transform: none;
  }
}
strong {
  font-weight: bold;
}
`;

export default GlobalStyles;
