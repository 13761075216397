import React from 'react';

/**
 * AnchorLink is a custom renderer
 * It parses the element and attaches an id to it to be used as an anchor
 */
const AnchorLink = ({node, children}) => {
  const id = children[0].toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-');
  return React.createElement(node.tagName, {id}, children);
};

export default AnchorLink;