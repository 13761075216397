import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { GET_TEAM_NAMES } from 'services/queries';
import { SubMenu } from 'Components';
import { Dropdown } from 'styles';
import { CURRENT_SEASON } from 'utils';

const SeasonDropdown = () => {
  const { loading, error, data } = useQuery(GET_TEAM_NAMES, {
    variables: { seasonId: CURRENT_SEASON },
  });

  if (error) return '';

  return (
    <Dropdown>
      <h3>Season</h3>
      <ul>
        <li>
          <Link to={`/schedule/${CURRENT_SEASON}`}>
            <FontAwesomeIcon icon={faCalendar} />
            Schedule
          </Link>
        </li>
        <li>
          <Link to={`/stats/${CURRENT_SEASON}`}>
            <FontAwesomeIcon icon={faChartLine} />
            Stats
          </Link>
        </li>
        {!loading && data && (
          <SubMenu
            heading={
              <>
                <FontAwesomeIcon icon={faUsers} />
                Teams
              </>
            }
          >
            {data.teams.length > 0 &&
              [...data.teams]
                .sort((teamA, teamB) => (teamA?.id > teamB?.id ? 1 : -1))
                .map(({ id, name }) => (
                  <li key={id}>
                    <a href={`/team/${CURRENT_SEASON}/${id}`}>{name}</a>
                  </li>
                ))}
          </SubMenu>
        )}
      </ul>
    </Dropdown>
  );
};

export default SeasonDropdown;
