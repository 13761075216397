import React, { useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { PageContainer } from 'Components';
import HeadingRenderer from 'data/markdownRenderers/AnchorLink';
import { design } from 'utils';
import rulesMd from 'data/rules.md';

const DocWrapper = styled.div`
  position: relative;
  display: grid;
  @media screen and (min-width: ${design.breakpoints[4]}) {
    grid-template-columns: 1fr 3fr;
  }
  h1 {
    grid-column: 1 / -1;
    margin-bottom: ${design.spacing[4]};
    text-decoration: underline;
    font-weight: bold;
  }
  > h1 {
    @media screen and (min-width: ${design.breakpoints[4]}) {
      display: none;
    }
  }
  em {
    font-style: italic;
  }
  ol {
    margin-left: ${design.spacing[2]};
    list-style-type: lower-alpha;
    li {
      margin: ${design.spacing[1.5]};
    }
  }
  ul {
    margin-left: ${design.spacing[3]};
    list-style-type: disc;
    li {
      margin: ${design.spacing[0.5]};
    }
  }
  li ol, li ul {
    margin-left: ${design.spacing[2]};
  }
`;
const TocWrapper = styled.nav`
  > div {
    padding-bottom: ${design.spacing[4]};
    margin-bottom: ${design.spacing[4]};
    border-bottom: 1px solid ${design.colors.gray[5]};
    @media screen and (min-width: ${design.breakpoints[4]}) {
      position: sticky;
      top: 10rem;
      padding-bottom: 0;
      border: 0;
    }
    counter-reset: toc-section;
  }
  h2 {
    font-size: ${design.fontSizes[3]};
    text-align: left;
    @media screen and (min-width: ${design.breakpoints[4]}) {
      margin-left: calc(${design.spacing[2]} + ${design.spacing[1.5]});
    }
  }
  ol {
    list-style-type: none;
    counter-reset: toc-item;
    li {
      &::before {
        content: counter(toc-section) '. ';
        counter-increment: toc-section;
      }
      ol {
        list-style-type: none;
        li {
          &::before {
            content: counter(toc-section) '.' counter(toc-item) '. ';
            counter-increment: toc-item;
          }
        }
      }
    }
  }
`;
const TocLink = styled.a`
  ${props => props.active ? `
    font-weight: bold;
    text-decoration: underline;
  ` : ``}
`;
const RulesWrapper = styled.div`
  line-height: 1.25;
  counter-reset: section;
  padding-bottom: 10rem;
  > h1 {
    display: none;
    @media screen and (min-width: ${design.breakpoints[4]}) {
      display: block;
    }
  }
  h2 {
    font-weight: bold;
    text-align: left;
    position: relative;
    counter-reset: subsection;
    &::before {
      counter-increment: section;
      content: counter(section) ' | ';
    }
    &:not(:first-child) {
    margin-top: 6rem;
      &::after {
        content: '';
        position: absolute;
        top: -3rem;
        left: 25%;
        right: 25%;
        margin: auto;
        height: 1px;
        background-color: ${design.colors.gray[2]};
      }
    }
  }
  h3 {
    margin: ${design.spacing[3]} 0 ${design.spacing[1.5]};
    font-weight: bold;
    font-size: ${design.fontSizes[2.5]};
    text-align: left;
    &::before {
      counter-increment: subsection;
      content: counter(section) '.' counter(subsection) ' | ';
    }
  }
`;

const RulesPage = () => {
  const [headings, setHeadings] = useState([]);
  const [rulesText, setRulesText] = useState('');
  const [activeId, setActiveId] = useState('');

  const observer = useRef();
  const rulesRef = useRef();

  useEffect(() => {
    fetch(rulesMd)
      .then((res) => res.text())
      .then((text) => setRulesText(text));
  });

  useEffect(() => {
    const sectionHeaders = Array.from(rulesRef.current.querySelectorAll('h2'));

    const tocHeadings = sectionHeaders.map((header) => {
      let list = header.nextElementSibling;
      const children = [];
      while (list && list.tagName !== 'H2') {
        if (list.tagName === 'H3') {
          children.push({ id: list.id, text: list.innerText });
        }
        list = list.nextElementSibling;
      }

      return {
        id: header.id,
        text: header.innerText,
        children,
      };
    });

    setHeadings(tocHeadings);
  }, [rulesText]);

  useEffect(() => {
    const handleObsever = (entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };
    observer.current = new IntersectionObserver(handleObsever, {
      rootMargin: '-20% 0% -35% 0px',
    });

    const elements = rulesRef.current.querySelectorAll('h2, h3', 'h4');
    elements.forEach((elem) => observer.current.observe(elem));

    return () => observer.current?.disconnect();

  }, [rulesRef.current, rulesText]);

  return (
    <PageContainer>
      <Helmet>
        <title>League Rules | GBH Players Club</title>
      </Helmet>
      <DocWrapper>
        <h1>League Rules</h1>
        <TocWrapper>
          <div>
            <h2>Table of Contents</h2>
            <ol>
              {headings.map((heading) => (
                <li key={heading.text}>
                  <TocLink active={heading.id === activeId} href={`#${heading.id}`}>{heading.text}</TocLink>
                  {heading.children?.length ? (
                    <ol>
                      {heading.children.map((child) => (
                        <li key={child.text}>
                          <TocLink active={child.id === activeId} href={`#${child.id}`}>{child.text}</TocLink>
                        </li>
                      ))}
                    </ol>
                  ) : (
                    <></>
                  )}
                </li>
              ))}
            </ol>
          </div>
        </TocWrapper>
        <RulesWrapper ref={rulesRef}>
          <h1>League Rules</h1>
          <div>
            <Markdown
              children={rulesText}
              components={{ h2: HeadingRenderer, h3: HeadingRenderer }}
              />
          </div>
        </RulesWrapper>
      </DocWrapper>
    </PageContainer>
  );
};

export default RulesPage;
