import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { design } from 'utils';

const SubMenuHeading = styled.h4`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  > svg {
    flex-grow: 0;
    content: '>';
    transition: transform 0.3s;
    ${(props) =>
      props.isOpen
        ? `
      transform: rotate(90deg);
    `
        : ``}
  }
`;
const SubMenuList = styled.ul`
  ${(props) =>
    props.isOpen
      ? `
    height: auto;
    max-height: 250px;
    display: block !important;
    &:before {
      content: '';
      position: absolute;
      left: ${design.spacing[3]};
      top: 0;
      bottom: ${design.spacing[4]};
      border-left: 2px solid ${design.colors.secondary};
      z-index: 1;
    }
    `
      : `
    height: 0;
    max-height: 0;
    display: none;
    `}
  border-radius: 0 !important;
  box-shadow: none !important;
  position: relative !important;
  transition: max-height 0.3s;
  overflow: hidden;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  li {
    border-radius: 0;
    position: relative;
    a {
      margin-left: ${design.spacing[3]};
      &:hover {
        background-color: ${design.colors.secondary} !important;
        color: ${design.colors.white} !important;
      }
    }
    &:before {
      content: '';
      position: absolute;
      left: ${design.spacing[2]};
      top: 1px;
      width: ${design.spacing[1]};
      height: 50%;
      border-bottom: 2px solid ${design.colors.secondary};
      border-left: 2px solid ${design.colors.secondary};
    }
    &:hover {
      background: none !important;
    }
  }
`;

const SubMenu = ({ heading, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li>
      <SubMenuHeading
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        role="button"
      >
        <div>{heading}</div>
        <FontAwesomeIcon icon={faChevronRight} />
      </SubMenuHeading>
      <SubMenuList isOpen={isOpen}>{children}</SubMenuList>
    </li>
  );
};

export default SubMenu;
