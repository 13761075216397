import React from 'react';
import styled from 'styled-components';
import { ArchiveDropdown, MerchDropdown, LeagueDropdown, SeasonDropdown } from 'Components';
import { design } from 'utils';

const NavBar = styled.ul`
  text-align: center;
  margin-top: -${design.spacing[2]};
  white-space: nowrap;
  > li {
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    &.is-active,
    &:hover {
      border-radius: ${design.radius[1]} ${design.radius[1]} 0 0;
      background-color: ${design.colors.white};
      a {
        color: ${design.colors.black};
        text-decoration: none;
      }
    }
  }
`;

const Nav = () => {
  return (
    <nav>
      <NavBar>
        <LeagueDropdown />
        <SeasonDropdown />
        <ArchiveDropdown />
        <MerchDropdown />
      </NavBar>
    </nav>
  );
};

export default Nav;
